import '../lib/i18n';

import { GlobalCSS } from '@daily/shared/components/GlobalCSS';
import { ThemeProvider } from '@daily/shared/contexts/Theme';
import { DailyThemeConfig } from '@daily-co/daily-js';
import * as Sentry from '@sentry/react';
import { useCallback, useEffect, useState } from 'react';

import { convertDailyThemeColors, defaultLightColors } from '../lib/theme';

if (process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT) {
  Sentry.init({
    dsn: 'https://468e374d8f614f9691bb3194f97efd42@o77906.ingest.sentry.io/5728081',
    release: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
    tracesSampleRate: 0.1,
    ignoreErrors: [
      'Non-Error exception captured',
      'Non-Error promise rejection captured',
      // See: https://stackoverflow.com/a/50387233
      'ResizeObserver loop limit exceeded',
    ],
    allowUrls: [/(\.staging)?\.daily\.co/],
    beforeBreadcrumb: (breadcrumb) => {
      if (breadcrumb.category.includes('redux')) return null;
      return breadcrumb;
    },
    integrations: [
      new Sentry.Integrations.Breadcrumbs({
        console: false,
      }),
    ],
  });
}

function PrebuiltApp({ Component, pageProps }) {
  const [launchConfig, setLaunchConfig] = useState<{
    musicMode?: boolean;
    theme?: DailyThemeConfig;
  }>(null);
  const [lightColors, setLightColors] = useState(defaultLightColors);
  const [darkColors, setDarkColors] = useState(defaultLightColors);

  const handleIframeDriverLaunchConfigMessage = useCallback(
    (ev: MessageEvent) => {
      if (ev.data.action !== 'iframe-launch-config') return;
      setLaunchConfig({
        musicMode: ev.data?.dailyConfig?.micAudioMode === 'music',
        theme: ev.data.theme as DailyThemeConfig,
      });
      window.removeEventListener(
        'message',
        handleIframeDriverLaunchConfigMessage
      );
    },
    []
  );

  // Tell the iframe driver that we're ready to receive our launch
  // configuration, which is needed before the app is rendered.
  // If we're not in an iframe, use a default instead (currently empty).
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const runsInFrame = params.has('emb');
    let driverSupportsLaunchConfig = false;
    if (params.has('dailyJsVersion')) {
      const versionParts = params.get('dailyJsVersion').split('.');
      const majorVersion = parseInt(versionParts[0], 10);
      const minorVersion = parseInt(versionParts[1], 10);
      if (majorVersion > 0 || minorVersion >= 16) {
        driverSupportsLaunchConfig = true;
      }
    }
    if (runsInFrame && driverSupportsLaunchConfig) {
      window.addEventListener('message', handleIframeDriverLaunchConfigMessage);
      window.parent.postMessage(
        {
          action: 'iframe-ready-for-launch-config',
          callFrameId: params.get('emb'),
          from: 'embedded',
          what: 'iframe-call-message',
        },
        '*'
      );
    } else {
      setLaunchConfig({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Set theme provided in the launch config.
  useEffect(() => {
    if (!launchConfig) return;
    const [light, dark] = convertDailyThemeColors(launchConfig.theme);

    setLightColors(light);
    setDarkColors(dark);
  }, [launchConfig]);

  return (
    launchConfig && (
      <ThemeProvider colors={lightColors} darkColors={darkColors}>
        <GlobalCSS background="transparent" />
        <Component {...pageProps} musicMode={launchConfig?.musicMode} />
      </ThemeProvider>
    )
  );
}

export default PrebuiltApp;
