import React, { useMemo } from 'react';

import {
  DARK_THEME_CLASS,
  LIGHT_THEME_CLASS,
  ThemeContext,
} from './ThemeProvider';
import { useTheme } from './useTheme';

interface Props {
  theme: 'light' | 'dark' | 'user';
}

export const ThemeScope: React.FC<Props> = ({ children, theme }) => {
  const { isDarkMode, ...props } = useTheme();

  const darkMode = useMemo(() => {
    switch (theme) {
      case 'light':
        return false;
      case 'dark':
        return true;
      case 'user':
        return isDarkMode;
    }
  }, [isDarkMode, theme]);

  return (
    <ThemeContext.Provider value={{ ...props, isDarkMode: darkMode }}>
      <div className={darkMode ? DARK_THEME_CLASS : LIGHT_THEME_CLASS}>
        {children}
      </div>
    </ThemeContext.Provider>
  );
};
