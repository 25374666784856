import {
  defaultDarkTheme,
  defaultLightTheme,
  ThemeColors,
} from '@daily/shared/contexts/Theme';
import { DailyThemeColors, DailyThemeConfig } from '@daily-co/daily-js';

export type CustomColors = {
  mainAreaBg: string;
  mainAreaBgAccent: string;
  mainAreaText: string;
};

export const defaultLightColors: ThemeColors<CustomColors> = {
  ...defaultLightTheme.colors,
  custom: {
    mainAreaBg: defaultLightTheme.colors.system.blueDark,
    mainAreaBgAccent: defaultLightTheme.colors.system.blue,
    mainAreaText: defaultLightTheme.colors.system.white,
  },
};
export const defaultDarkColors: ThemeColors<CustomColors> = {
  ...defaultDarkTheme.colors,
  custom: {
    mainAreaBg: defaultDarkTheme.colors.system.blueDark,
    mainAreaBgAccent: defaultDarkTheme.colors.system.blueLight,
    mainAreaText: defaultDarkTheme.colors.system.white,
  },
};

const getThemeColors = (
  colors: DailyThemeColors,
  fallback: ThemeColors<CustomColors>
) => ({
  accent: colors?.accent ?? fallback.accent,
  accentText: colors?.accentText ?? fallback.accentText,
  background: colors?.background ?? fallback.background,
  backgroundAccent: colors?.backgroundAccent ?? fallback.backgroundAccent,
  baseText: colors?.baseText ?? fallback.baseText,
  border: colors?.border ?? fallback.border,
  supportiveText: colors?.supportiveText ?? fallback.supportiveText,
  custom: {
    mainAreaBg: colors?.mainAreaBg ?? fallback.custom?.mainAreaBg,
    mainAreaBgAccent:
      colors?.mainAreaBgAccent ?? fallback.custom?.mainAreaBgAccent,
    mainAreaText: colors?.mainAreaText ?? fallback.custom?.mainAreaText,
  },
  system: fallback.system,
});

export const convertDailyThemeColors = (
  theme: DailyThemeConfig
): [ThemeColors<CustomColors>, ThemeColors<CustomColors>] => {
  if (!theme) {
    return [defaultLightColors, defaultDarkColors];
  }
  if ('light' in theme && 'dark' in theme) {
    return [
      getThemeColors(theme.light.colors, defaultLightColors),
      getThemeColors(theme.dark.colors, defaultDarkColors),
    ];
  }
  return [
    getThemeColors(theme.colors, defaultLightColors),
    getThemeColors(theme.colors, defaultLightColors),
  ];
};
