import { captureException } from '@sentry/react';
import { useEffect, useState } from 'react';

export const useMediaQuery = (query: string) => {
  const [matches, setMatches] = useState(false);

  useEffect(() => {
    const handleMQChange = ({ matches }) => setMatches(matches);
    const result = window.matchMedia(query);
    try {
      result.addEventListener('change', handleMQChange);
    } catch {
      try {
        result.addListener(handleMQChange);
      } catch (e) {
        captureException(e);
      }
    }
    setMatches(result.matches);
    return () => {
      try {
        result.removeEventListener('change', handleMQChange);
      } catch {
        try {
          result.removeListener(handleMQChange);
        } catch (e) {
          captureException(e);
        }
      }
    };
  }, []);

  return matches;
};
