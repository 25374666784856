import React, { useEffect } from 'react';

import { useTheme } from '../../contexts/Theme';
import { isStaging } from '../../lib/env';
import { throttle } from '../../lib/throttle';

const basePath = `https://c.${
  isStaging() ? 'staging.' : ''
}daily.co/static/assets`;

interface Props {
  background?: string;
}

export const GlobalCSS: React.FC<Props> = ({ background }) => {
  const { fontFamilies, lineHeights } = useTheme();

  const handleMouseMove = throttle(() => {
    document.body.classList.add('with-mouse');
    document.body.classList.remove('with-keyboard');
  }, 200);
  const handleKeyboard = (e: KeyboardEvent) => {
    if (
      ![
        'ArrowDown',
        'ArrowLeft',
        'ArrowRight',
        'ArrowUp',
        'End',
        'Enter',
        'Escape',
        'Home',
        'PageDown',
        'PageUp',
        'Tab',
      ].includes(e.key)
    )
      return;
    document.body.classList.add('with-keyboard');
    document.body.classList.remove('with-mouse');
  };

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove);
    window.addEventListener('keydown', handleKeyboard);
    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('keydown', handleKeyboard);
    };
  }, []);

  return (
    <>
      <style jsx global>{`
        @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;700;900&display=swap');
        @font-face {
          font-family: 'InputMonoRegular';
          font-display: swap;
          font-style: normal;
          font-weight: normal;
          font-stretch: normal;
          src: url('${basePath}/InputMonoRegular.woff2') format('woff2'),
            url('${basePath}/InputMonoRegular.woff') format('woff'),
            url('${basePath}/InputMonoRegular.eot') format('eot');
        }
        @font-face {
          font-family: 'GraphikRegular';
          font-display: swap;
          font-style: 'normal';
          font-weight: 'normal';
          font-stretch: 'normal';
          src: url('${basePath}/GraphikRegular.woff2') format('woff2'),
            url('${basePath}/GraphikRegular.woff') format('woff'),
            url('${basePath}/GraphikRegular.eot') format('eot');
        }
        @font-face {
          font-family: 'GraphikRegularItalic';
          font-display: swap;
          font-style: 'normal';
          font-weight: 'normal';
          font-stretch: 'normal';
          src: url('${basePath}/GraphikRegularItalic.woff2') format('woff2'),
            url('${basePath}/GraphikRegularItalic.woff') format('woff'),
            url('${basePath}/GraphikRegularItalic.eot') format('eot');
        }
        @font-face {
          font-family: 'GraphikMedium';
          font-display: swap;
          font-style: 'normal';
          font-weight: 'normal';
          font-stretch: 'normal';
          src: url('${basePath}/GraphikMedium.woff2') format('woff2'),
            url('${basePath}/GraphikMedium.woff') format('woff'),
            url('${basePath}/GraphikMedium.eot') format('eot');
        }
        @font-face {
          font-family: 'GraphikMediumItalic';
          font-display: swap;
          font-style: 'normal';
          font-weight: 'normal';
          font-stretch: 'normal';
          src: url('${basePath}/GraphikMediumItalic.woff2') format('woff2'),
            url('${basePath}/GraphikMediumItalic.woff') format('woff'),
            url('${basePath}/GraphikMediumItalic.eot') format('eot');
        }
        @font-face {
          font-family: 'GraphikSemibold';
          font-display: swap;
          font-style: 'normal';
          font-weight: 'normal';
          font-stretch: 'normal';
          src: url('${basePath}/GraphikSemibold.woff2') format('woff2'),
            url('${basePath}/GraphikSemibold.woff') format('woff'),
            url('${basePath}/GraphikSemibold.eot') format('eot');
        }
        @font-face {
          font-family: 'GraphikSemiboldItalic';
          font-display: swap;
          font-style: 'normal';
          font-weight: 'normal';
          font-stretch: 'normal';
          src: url('${basePath}/GraphikSemiboldItalic.woff2') format('woff2'),
            url('${basePath}/GraphikSemiboldItalic.woff') format('woff'),
            url('${basePath}/GraphikSemiboldItalic.eot') format('eot');
        }

        *,
        *::before,
        *::after {
          box-sizing: border-box;
        }

        :root {
          --base-font-size: 12;
        }

        html {
          font-size: 75%;
          height: -webkit-fill-available;
          margin: 0;
        }

        body {
          background: var(--body-bg);
          color: var(--body-color);
          line-height: ${lineHeights.base};
          margin: 0;
          min-height: 100vh;
          min-height: -webkit-fill-available;
          overflow-x: hidden;
          padding: 0;
        }
        .scroll-lock {
          overflow: hidden;
          padding-top: var(--header-height);
        }
        ul,
        ol {
          list-style-position: outside;
          margin: 0;
          padding: 8px 0 8px 16px;
          text-indent: 0;
        }
        @media screen and (max-device-width: 480px) {
          body {
            -webkit-text-size-adjust: none;
          }
        }
      `}</style>
      <style jsx global>
        {`
          body {
            ${background ? `background-color: ${background};` : ''}
            font-family: ${fontFamilies.regular};
          }
        `}
      </style>
    </>
  );
};
