import i18n from 'i18next';
import backend from 'i18next-http-backend';
import getConfig from 'next/config';
import { initReactI18next } from 'react-i18next';

const { assetPrefix } = getConfig().publicRuntimeConfig;

i18n
  .use(backend)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    backend: {
      addPath: null,
      loadPath: `${assetPrefix}/locales/{{lng}}/{{ns}}.json`,
    },
    supportedLngs: [
      'de',
      'en',
      'es',
      'fi',
      'fr',
      'it',
      'jp',
      'ka',
      'nl',
      'no',
      'pl',
      'pt',
      'ru',
      'sv',
      'tr',
    ],
    lng: 'en',
    fallbackLng: 'en', // use en if detected lng is not available

    saveMissing: false,

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
    react: {
      useSuspense: false,
    },
  });

export default i18n;
