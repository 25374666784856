import Cookies from 'js-cookie';
import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import { useMediaQuery } from '../../hooks/useMediaQuery';
import { getCookieDomain } from '../../lib/getCookieDomain';
import { defaultDarkTheme } from './darkTheme';
import { defaultLightTheme } from './lightTheme';
import { getThemeCSS } from './theme';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import type { Theme, ThemeColors, ThemeOption } from './types';
import { useCustomTheme } from './useCustomTheme';

export const DARK_THEME_CLASS = 'theme-dark';
export const LIGHT_THEME_CLASS = 'theme-light';

interface Props {
  colors?: ThemeColors;
  darkColors?: ThemeColors;
}

interface ThemeProviderValue extends Theme {
  currentTheme: ThemeOption;
  isDarkMode: boolean;
  setDarkColors(colors: ThemeColors);
  setLightColors(colors: ThemeColors);
  setTheme(theme: ThemeOption): void;
}

export const ThemeContext = createContext<ThemeProviderValue>({
  ...defaultLightTheme,
  currentTheme: 'user',
  isDarkMode: false,
  setDarkColors: () => {},
  setLightColors: () => {},
  setTheme: () => {},
});

export const ThemeProvider: React.FC<Props> = ({
  children,
  colors = defaultLightTheme.colors,
  darkColors = defaultDarkTheme.colors,
}) => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const [currentTheme, setCurrentTheme] = useState<ThemeOption>('user');
  const [currentLightColors, setCurrentLightColors] = useState(colors);
  const [currentDarkColors, setCurrentDarkColors] = useState(darkColors);

  useEffect(() => {
    setCurrentLightColors(colors);
  }, [colors]);
  useEffect(() => {
    setCurrentDarkColors(darkColors);
  }, [darkColors]);

  const isDarkMode = useMemo(
    () =>
      currentTheme === 'dark' || (currentTheme === 'user' && prefersDarkMode),
    [currentTheme, prefersDarkMode]
  );

  const customLightTheme = useCustomTheme(currentLightColors);
  const customDarkTheme = useCustomTheme(currentDarkColors);

  const lightThemeCSS = getThemeCSS(customLightTheme.colors);
  const darkThemeCSS = getThemeCSS(customDarkTheme.colors);

  useEffect(() => {
    const theme = Cookies.get('theme') as ThemeOption;
    if (!theme || theme === 'user') {
      setCurrentTheme('user');
      return;
    }
    setCurrentTheme(theme);
  }, [prefersDarkMode]);

  useEffect(() => {
    document.documentElement.classList.remove(
      LIGHT_THEME_CLASS,
      DARK_THEME_CLASS
    );
    if (currentTheme !== 'user') {
      document.documentElement.classList.add(
        currentTheme === 'dark' ? DARK_THEME_CLASS : LIGHT_THEME_CLASS
      );
    }
  }, [currentTheme]);

  const setTheme = useCallback((value: ThemeOption) => {
    const cookieDomain = getCookieDomain(window.location.hostname);
    const cookieSettings = {
      domain: cookieDomain !== window.location.hostname ? cookieDomain : null,
    };
    switch (value) {
      case 'dark':
        setCurrentTheme('dark');
        Cookies.set('theme', value, cookieSettings);
        document.documentElement.classList.add(DARK_THEME_CLASS);
        document.documentElement.classList.remove(LIGHT_THEME_CLASS);
        break;
      case 'light':
        setCurrentTheme('light');
        Cookies.set('theme', value, cookieSettings);
        document.documentElement.classList.add(LIGHT_THEME_CLASS);
        document.documentElement.classList.remove(DARK_THEME_CLASS);
        break;
      case 'user':
        setCurrentTheme('user');
        Cookies.remove('theme', cookieSettings);
        document.documentElement.classList.remove(
          DARK_THEME_CLASS,
          LIGHT_THEME_CLASS
        );
        break;
    }
  }, []);

  return (
    <ThemeContext.Provider
      value={{
        ...(isDarkMode ? customDarkTheme : customLightTheme),
        currentTheme,
        isDarkMode,
        setDarkColors: setCurrentDarkColors,
        setLightColors: setCurrentLightColors,
        setTheme,
      }}
    >
      {children}
      <style jsx global>{`
        :root,
        .theme-light {
          ${lightThemeCSS}

          color: var(--text-default);
        }
        .theme-dark {
          ${darkThemeCSS}

          color: var(--text-default);
        }
        @media (prefers-color-scheme: light) {
          :root {
            ${lightThemeCSS}

            color: var(--text-default);
          }
          .theme-dark {
            ${darkThemeCSS}

            color: var(--text-default);
          }
        }
        @media (prefers-color-scheme: dark) {
          :root {
            ${darkThemeCSS}

            color: var(--text-default);
          }
          .theme-light {
            ${lightThemeCSS}

            color: var(--text-default);
          }
        }
      `}</style>
    </ThemeContext.Provider>
  );
};
